'use client'
import { useState } from "react";

export default function Header() {
    const [dropdownOpen, setdropdownOpen] = useState(false);
    return (
        <header id="ametvet" className='w-full'>
            <div className='max-w-[1440px] 2xl:mx-auto mx-[24px] h-[90px] flex justify-between items-center bg-white rounded-lg mt-6 relative'>
                <img src="/logo.svg" alt="logo" className='w-[216px]' />
                <nav className='hidden px-6 items-center h-full md:flex'>
                    <a href="#ametvet" className='text-[#ffffff] font-medium bg-[#201836] h-full px-3 flex items-center'>A Metvet</a>
                    <a href="#industria" className='text-[#201836] font-medium h-full px-3 flex items-center'>Industria</a>
                    <a href="#digital" className='text-[#201836] font-medium h-full px-3 flex items-center'>Digital</a>
                    <a href="#feedback" className='text-[#201836] font-medium h-full px-3 flex items-center'>Feedback</a>
                    <a href="#contato" className='text-[#201836] font-medium h-full px-3 flex items-center'>Contato</a>
                </nav>

                <div
                    onClick={() => setdropdownOpen(!dropdownOpen)}
                    className=" md:hidden flex justify-center items-center
                 hover:cursor-pointer mr-6
                 "
                >
                    <img src="/Menu.svg" alt="menu" />
                </div>

                <div
                    className={`${dropdownOpen
                            ? `top-full opacity-100 visible`
                            : "top-[110%] invisible opacity-0"
                        } absolute left-0 z-40 mt-2 w-full rounded border-[.5px] border-light bg-white py-5 shadow-card transition-all`}
                >
                    <a
                        href="#ametvet"
                        className="block py-2 px-5 text-base font-semibold text-body-color hover:bg-primary hover:bg-opacity-5 hover:text-primary"
                        onClick={() => setdropdownOpen(!dropdownOpen)}
                    >
                        A Metvet
                    </a>
                    <a
                        href="#industria"
                        className="block py-2 px-5 text-base font-semibold text-body-color hover:bg-primary hover:bg-opacity-5 hover:text-primary"
                        onClick={() => setdropdownOpen(!dropdownOpen)}
                    >
                        Industria
                    </a>
                    <a
                        href="#digital"
                        className="block py-2 px-5 text-base font-semibold text-body-color hover:bg-primary hover:bg-opacity-5 hover:text-primary"
                        onClick={() => setdropdownOpen(!dropdownOpen)}
                    >
                        Digital
                    </a>
                    <a
                        href="#feedback"
                        className="block py-2 px-5 text-base font-semibold text-body-color hover:bg-primary hover:bg-opacity-5 hover:text-primary"
                        onClick={() => setdropdownOpen(!dropdownOpen)}
                    >
                        Feedback
                    </a>
                    <a
                        href="#contato"
                        className="block py-2 px-5 text-base font-semibold text-body-color hover:bg-primary hover:bg-opacity-5 hover:text-primary"
                        onClick={() => setdropdownOpen(!dropdownOpen)}
                    >
                        Contato
                    </a>
                </div>
            </div>
        </header>
    )
}